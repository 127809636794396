/* src/assets/css/global.css */

/* Basis Reset en Normale Stijlen */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    font-family: 'Roboto', sans-serif; /* Standaard font voor tekst */
    line-height: 1.6;
    background-color: #f2f2f2; /* Hoofdachtergrondkleur */
    overflow-x: hidden; /* Voorkom horizontaal scrollen */
    scroll-behavior: smooth; /* Zorg ervoor dat scrollen soepel is */
}

button {
    cursor: pointer;
    border: none;
    background: none;
    font-family: inherit;
    font-size: 1rem;
    font-weight: bold;
    transition: color 0.3s ease; /* Overgangseffect voor kleur */
}

/* Media Queries voor Responsiveness */
@media (max-width: 1200px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .footer-about, .footer-links, .footer-social {
        min-width: 100%;
        text-align: center;
    }
}

@media (max-width: 992px) {
    h1 {
        font-size: 2rem; /* Iets kleinere titel op medium schermen */
    }

    h2 {
        font-size: 1.5rem; /* Iets kleinere subtitel op medium schermen */
    }

    h3 {
        font-size: 1.125rem;
    }
}

@media (max-width: 768px) {
    .footer-content {
        gap: 0.5rem; /* Aangepaste ruimte tussen secties */
    }

    .footer-social img {
        width: 20px; /* Aangepaste grootte */
        height: 20px; /* Aangepaste grootte */
    }
}

@media (max-width: 576px) {
    body {
        font-size: 1rem; /* Grotere tekstgrootte voor leesbaarheid op mobiele schermen */
    }

    .footer-content {
        flex-direction: column;
    }

    .footer-social img {
        width: 18px; /* Aangepaste grootte */
        height: 18px; /* Aangepaste grootte */
    }

    h1 {
        font-size: 1.75rem; /* Grotere titels op kleine schermen */
    }

    h2 {
        font-size: 1.25rem; /* Grotere subtitels op kleine schermen */
    }

    h3 {
        font-size: 1rem; /* Grotere subtitels op kleine schermen */
    }

    p {
        font-size: 1rem; /* Grotere tekst op kleine schermen */
    }
}
