.triforce-svg {
  fill: #b5651d; /* Warm bruine kleur */
  stroke: #8b4513; /* Donkerdere bruine rand */
  stroke-width: 2;
  transition: fill 0.3s ease, stroke 0.3s ease;
}

.triforce-svg:hover .triforce-triangle {
  fill: #f4a460; /* Lichtere warme kleur bij hover */
  stroke: #a0522d; /* Lichtere randkleur bij hover */
}
